.Home .lander {
  /* padding: 80px 0; */
  text-align: center;
}

.Home .lander h1 {
  font-weight: 100pt;
  font-size: 12rem;
}

.Home .lander .body-elem p {
  font-size: 4rem;
  margin-bottom: 2rem;
  color: #333;
}
.Home .lander .body-elem a {
  font-size: 3rem;
}

.Home .lander section {
  min-height: 90vh;
  border-bottom-color: rgb(236, 240, 241);
  border-bottom-style: solid;
  border-bottom-width: 5px;
  overflow: hidden;
}

.Home .lander section:last-child {
  margin-bottom:0;
  border-bottom-width: 0px;
}

.body-elem--element {
  margin-top: 4rem;
  padding-right: 5rem;
}

.body-elem {
  padding-top:22vh !important;
  padding-bottom: 20vh !important;
  height: 95vh;
  top:0;
  overflow:hidden;
} 

.body-elem::after {
  content: "";
  clear: both;
}

.btn-info {
  margin-right: 1rem;
}